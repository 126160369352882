.sizeFotoCategoria{
    width: 300px;
    height: 300px;
    margin: auto;
}

.sizeCategoria{
    width: 350px;
    height: 400px;
    margin: auto;
}

.pieDeFoto {
    text-align: center;
    margin: auto;
}
/*
import './Catalogo.css';

<IonItem className="sizeCategoria">

IonImg className="sizeFotoCategoria"
*/