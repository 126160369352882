.encabezadoApartado{
    padding-top: 20px !important;
    font-size:  27px !important;
    white-space: unset;
    padding-bottom: 20px !important;
}

.contenidoApartado {
    margin-left: 20px;
}

.estiloLink{
    color: blue;
    cursor: pointer;
}

.iconoDesplegarTransparencia{
    font-size: 20px;
}

.estiloTransparencia {
    padding-left: 8.3%;
    padding-right: 8.3%;

}