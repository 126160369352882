.pull-left {
    float: left!important;
}

.correccionDeFondo {
    background-color: white !important;
    color: black;
}

.logoEncabezado {
    height: 60px;
    width: 165px;
}

.logoPie {
    max-height: 167.438px;
    max-width: 360px;
    background-color: #292f38 !important;
}

.encabezadoEspaciado {
    padding-top: 10px;
    padding-bottom: 10px;
}

.fuente {
    font-family: 'Open Sans', Arial, sans-serif !important;
}

.colorPiePagina {
    background-color: #292f38;
    color: #87919F;
}

.colorApartadosPiePagina {
    background-color: #30343c !important;
    color: #ccc !important;
}

.titulo{
    margin: auto !important;
    padding-top: 35px;
    padding-bottom: 35px;
    font-size:  50px !important;
}

.botonesBarra{
    margin: auto;
}


.espacioFinal {
    padding-bottom: 35px;
}

.pull-left {
    float: left!important;
}

.correccionDeFondo {
    background-color: white;
    color: black;
}

.logoEncabezado {
    height: 60px;
    width: 165px;
}

.logoPie {
    max-height: 167.438px;
    max-width: 360px;
    background-color: #292f38 !important;
}

.encabezadoEspaciado {
    padding-top: 10px;
    padding-bottom: 10px;
}

.fuente {
    font-family: 'Open Sans', Arial, sans-serif !important;
}

.colorPiePagina {
    background-color: #292f38;
    color: #87919F;
}

.colorApartadosPiePagina {
    background-color: #30343c !important;
    color: #ccc !important;
}

.titulo{
    margin: auto !important;
    padding-top: 35px;
    padding-bottom: 35px;
    text-align: center;
    font-size:  50px !important;
}

.subtextoTitulo {
    margin: auto !important;
    font-size:  18px !important;
    color: #87919F;
}


.botonesBarra{
    margin: auto;
}

.subtexto {
    color: #87919F;
    font-size:  23px !important;
    text-align: center;
    font-family: 'Open Sans', Arial, sans-serif !important;
}

.espacioFinal {
    padding-bottom: 35px;
}

.pieDerechos{
    margin: 17px;
}

.encabezadoDerecha{
    position:fixed;
    top:0;
    right: 0;
    margin-top: 25px;
    margin-right: 15px;
}

.cursosSeleccionable {
    cursor: pointer;
}

.encabezadoIzquierda{
    margin-left: 15px;
    margin-top: 15px;
    padding-bottom: 5px;

}

.barraEncabezado{
    display: inline;
}

.subapartadosContactar {
    line-height: 10px !important;
    margin-bottom: 30px;
    color: #87919F;
}

.subapartadosDerecha{
 text-align: right !important;
}

.gridFooter {
    background-color: #30343c !important;
    color: #ccc !important;
    max-height: 300px;
}

.centroFooter {
    width: 100%;
    height: 200px;
}

.logoFooter {
    background-color: #292f38 !important; 
    width: 75%;
    height: 100%;
    margin: auto;
}

.derechaFooter {
    padding: 10px;
    float:right
}

.fullWidthClass {
    width: 100%;
}

.fullFooter{
    width: 100%;
    bottom: 0;
    left: 0;
}

.pieDerechos{
    margin: 17px;
}

.encabezadoDerecha{
    position:fixed;
    top:0;
    right: 0;
    margin-top: 25px;
    margin-right: 15px;
}

.cursosSeleccionable {
    cursor: pointer;
}

.encabezadoIzquierda{
    margin-left: 15px;
    margin-top: 15px;
    padding-bottom: 5px;

}

.barraEncabezado{
    display: inline;
}

.subapartadosContactar {
    line-height: 10px !important;
    margin-bottom: 30px;
    color: #87919F;
}

.subapartadosDerecha{
 text-align: right !important;
}

#icono_menu {
    font-size: 30px;
    padding-left: 0.5em;
}

@media(min-width: 1070px), (min-height: 775px) {
    .footerMobile {
      display: block;
    }
  
    .headerDesktop {
      display: block;
    }
  
    .headerMobile {
      display: none;
    }
   
  }

  @media(max-width: 1070px), (max-height: 775px) {
    .footerMobile {
      display: none;
    }
  
    .headerDesktop {
      display: none;
    }
  
    .headerMobile {
      display: block;
    }
  }

.headerPath{
    background-color: white;
    color:black;
    padding-left: 15px;
    padding-bottom: 15px;
}



.textAcceso {
    display: inline-block;
    vertical-align: middle;
}
  
.iconAcceso {
display: inline-block;
vertical-align: middle;
}
   
.categoriaActual{
    color: #c52720;
    font-weight: bold;
}

.iconoCaracteristica{
    height: 20px;
    width: 20px;
    margin: auto;
}

.logosSubvencion {
    max-height: 60px;
}