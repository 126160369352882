.main-div {
    margin: auto;
    width: 50% !important;
    height: 100%;
    text-align: center;
    display: table;
}
.child-div {
    display: table-cell;
    vertical-align: middle;
}
.input-div {
    text-align: start;
}

.tab-selected{
  background-color: grey;
}

#header{
  display: inline-flex;
  width: 100%;
}

#icono_menu {
  font-size: 30px;
  padding-left: 0.5em;
}

#container {
  display: inline-block;
  cursor: pointer;
  padding: 7px;
}

#bar1, #bar2, #bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.change #bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
  transform: rotate(-45deg) translate(-9px, 6px) ;
}

/* Fade out the second bar */
.change #bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change #bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
  transform: rotate(45deg) translate(-8px, -8px) ;
}

ion-spinner {
  width: 40px !important;
  height: 40px !important;
}

#derecha {
    display: flex;
    justify-content: space-between;
}

.iconStyle {
  font-size: 30px;
  padding: 5px;
}

.rightGravity {
  justify-content: right;
}

.itemDetails {
  width: 100%;
}

.addIconDiv {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.detailsItemDiv {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 3px;
}

.divCantidades {
  padding-top: 3px;
  padding-bottom: 3px;
}

.redHeader {
  background: #eb445a;
  color: white;
}

.tab-selected {
  background: #eb445a !important;
  color: white !important;
}

.iconDisabled {
  color: lightgray !important;
}

.pedidoPicking {
  padding-left: 10px;
}

.login-class {
  max-width: 75% !important;
  min-width: 75% !important;
  width: 75% !important;
  margin: auto;
  padding: 10px 10px 10px 10px;
}

.top-margen {
  margin-top: 25%;
}

.boton-login {
  margin: auto !important;
  /*display:flex;*/
}

#menuLogin {
  margin: auto;
  max-height: 882px;
  max-width: 670px;
}

#tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*
ion-row{
  display: flex;
  align-items: center;
  justify-content: center;
  /*height: 100%;
}
*/
ion-col {
  display: flex;
  text-align: left;
  /*align-items: center;*/
  justify-content: left !important;
  /*margin: auto;*/
}

.centrarTexto {
  justify-content: center !important;
}
.negro {
  color: grey;
}

.rojo{
  background-color: red;
}

.negrita {
  font-weight: bold;
}
/*
.estiloInput {
  background-color: black;
  color: white;
}
*/
.estiloInput {
  background-color: #f1f0ee;
  color: black;
}

.tituloLogin {
  font-size: 30px; 
  padding-bottom: 25px;
  
}

.errorLogin{
  color: red;
  margin: auto;
  text-align: center;
}

.textoAccesoClientes{
  float: top;
}