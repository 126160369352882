.fotoArticulo {
    width: 350px;
    height: 350px;
}

.textoArticulo{
    padding-top: 20%;
}
/*
.contenedorArticulo {
    
}*/

.estiloPath {
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
}

.contenedorArticuloMovil{
    padding: 10%;
    
}

.imagenArticuloMovil{
    width: 75%;
    height: 75%;
    margin: auto;
}

.textoArticuloMovil {
    margin: auto;
    width: 75%;
    height: 75%;
}